import React  from "react";
const welcomeMessage = (
    <span>
      <br>
      </br>
      Welcome! ✌🏽 <br />
      Type "help" to see all supported commands 🌝 <br /> <br />
    </span>
  );

  export default welcomeMessage;